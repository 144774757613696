import request from './request';

export function getRoleList(params){
    return request({
        url: 'role/getRoleList',
        method: 'post',
        data: params
    });
}


export function deleteRole(params){
    return request({
        url: 'role/deleteRole',
        method: 'post',
        data: params
    });
}


export function addRole(params){
    return request({
        url: 'role/addRole',
        method: 'post',
        data: params
    });
}


export function getRoleRules(params){
    return request({
        url: 'role/getRoleRules',
        method: 'post',
        data: params
    });
}


export function editRole(params){
    return request({
        url: 'role/editRole',
        method: 'post',
        data: params
    });
}

export function getRuleTree(params){
    return request({
        url: 'role/getRuleListTree',
        method: 'post',
        data: params
    });
}


