<template>
    <div class="card card-custom">
        <div class="card-header">
            <h3 class="card-title">{{title}}</h3>
            <button type="button" class="close" @click="cancelForm()">
                <i aria-hidden="true" class="ki ki-close"></i>
            </button>
        </div>

        <!--begin::Form-->
        <form class="form fv-plugins-bootstrap fv-plugins-framework" ref="dataForm" id="dataForm">
            <div class="card-body" style="max-height:500px;overflow:auto;">
                <div class="form-group row">
                    <label class="col-lg-3 col-form-label">角色名称 <span class="text-danger">*</span>:</label>
                    <div class="col-lg-6">
                        <input type="text" class="form-control" placeholder="请输入权限名称" v-model="data_form.role_name" name="role_name">
                        <span class="form-text text-muted"></span>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-lg-3 col-form-label">角色说明 <span class="text-danger">*</span>:</label>
                    <div class="col-lg-6">
                        <input type="text" class="form-control" placeholder="请输入权限标题" v-model="data_form.description" name="description">
                        <span class="form-text text-muted"></span>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-lg-3 col-form-label">角色权限 <span class="text-danger">*</span>:</label>
                    <div class="col-lg-6">

                        <v-treeview
                                hoverable
                                dense
                                selectable
                                selected-color="red"
                                item-key="id"
                                :items="rule_list"
                                :value="ruleSelectNode"
                                @input="ruleTreeSelect"
                        ></v-treeview>
                        <input type="hidden" class="form-control" placeholder="请选择角色包含的权限" v-model="data_form.rule_ids" name="rule_ids" ref="rule_ids_input">
                        <span class="form-text text-muted">请选择角色包含的权限</span>
                    </div>
                </div>
            </div>

            <div class="card-footer">
                <button ref="data_form_submit" class="btn btn-primary mr-2">保存</button>
                <button class="btn btn-secondary" type="reset" @click="cancelForm()">关闭</button>
            </div>
        </form>
        <!--end::Form-->
    </div>
</template>
<script>
    import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
    import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
    import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
    import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
    import KTUtil from "@/assets/js/components/util";

    import {addRole, getRuleTree} from '@/api/role';

    export default {
        name: "role_add",
        props: {
            title: String,
            dialog_data: Object
        },
        created:function() {
            var _this = this;
            getRuleTree({}).then(function(res){
                _this.rule_list = res.response;
            });
        },
        components :{

        },
        data() {
            return {
                data_form:{
                    pid: this.dialog_data.form_data.pid,
                    role_name: '',
                    description: '',
                    rule_ids: ''
                },
                rule_list: [],
                ruleSelectNode: [0]
            }
        },
        mounted() {
            const data_form = KTUtil.getById("dataForm");

            //表单数据验证
            this.fv = formValidation(data_form, {
                fields: {
                    role_name: {
                        validators: {
                            notEmpty: {
                                message: "角色名称不能为空"
                            }
                        }
                    },
                    description: {
                        validators: {
                            notEmpty: {
                                message: "角色描述不能为空"
                            }
                        }
                    },
                    rule_ids: {
                        validators: {
                            notEmpty: {
                                message: "角色包含权限不能为空"
                            }
                        }
                    },
                },
                plugins: {
                    trigger: new Trigger(),
                    submitButton: new SubmitButton(),
                    bootstrap: new Bootstrap()
                }
            });
            this.fv.on("core.form.valid", () => {
                var _this = this;

                //按钮加载动画
                const submitButton = this.$refs["data_form_submit"];
                submitButton.classList.add("spinner", "spinner-light", "spinner-right");

                addRole(_this.data_form).then(function (res){
                    if (res) {
                        KTUtil.toast(_this, '结果提醒', '保存成功', 'success');
                        data_form.reset();
                        _this.$emit('opeareResultHandler', 1);
                    }
                    submitButton.classList.remove(
                        "spinner",
                        "spinner-light",
                        "spinner-right"
                    );
                });
            });
        },
        methods: {
            cancelForm(){
                this.$emit('opeareResultHandler', 0);

            },
            ruleTreeSelect(array) {
                this.data_form.rule_ids = array;
                this.$refs["rule_ids_input"].parentElement.lastChild.remove();
            }
        }
    }
</script>
